









import { Component, Vue, Ref } from 'vue-property-decorator';
import ContextFavorecido from '@/components/ContextOptions/ContextFavorecido.vue';
import ContextIndicador from '@/components/ContextOptions/ContextIndicador.vue';
import VueContext from 'vue-context';

@Component({
  components: {
    ContextFavorecido,
    ContextIndicador,
  },
})
export default class IndicadorContext extends Vue {
  @Ref() readonly menu!: VueContext;

  public open(event: MouseEvent, item: IIndicador): void {
    this.menu.open(event, item);
  }

  public close(): void {
    this.menu.close();
    this.$emit('close');
  }
}
