import i18n from '@/i18n';
import { BvTableField } from 'bootstrap-vue';

const columns: Array<{ key: string } & BvTableField> = [
  { label: i18n.tc('colunas.nome.indicador', 1) as string, key: 'nome', sortable: true },
  {
    label: i18n.t('indicador.colunas.comissoes') as string,
    key: 'comissoes.quantidade_disponivel',
    thStyle: 'width: 100px',
    sortable: true,
  },
  {
    label: i18n.t('colunas.total.disponivel') as string,
    key: 'comissoes.valor_disponivel',
    thStyle: 'width: 100px',
    sortable: true,
  },
  {
    label: i18n.t('colunas.saques.pendentes') as string,
    key: 'saques.pendentes',
    thStyle: 'width: 100px',
    sortable: true,
  },
  {
    label: i18n.t('colunas.saques.pagos') as string,
    key: 'saques.pagos',
    thStyle: 'width: 100px',
    sortable: true,
  },
  {
    label: i18n.tc('colunas.data.cadastro', 2) as string,
    key: 'time',
    thStyle: 'width: 100px',
    sortable: true,
  },
];

const columnsFilter: IFiltroField[] = [
  { label: i18n.t('colunas.codigo.indicador') as string, id: 'usuarios.id', type: 'id' },
  { label: i18n.tc('colunas.nome.indicador', 2) as string, id: 'usuarios.nome', type: 'text' },
  {
    label: i18n.t('colunas.total.comissoes') as string,
    id: 'comissoes.quantidade_disponivel',
    type: 'number',
  },
  {
    label: i18n.t('colunas.total.disponivel') as string,
    id: 'comissoes.valor_disponivel',
    type: 'number',
  },
  { label: i18n.t('colunas.saques.pendentes') as string, id: 'saques.pendentes', type: 'number' },
  { label: i18n.t('colunas.saques.pagos') as string, id: 'saques.pagos', type: 'number' },
  { label: i18n.tc('colunas.data.cadastro', 2) as string, id: 'usuarios.time', type: 'date' },
];

export { columns, columnsFilter };
