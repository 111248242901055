





















































import { Component, Vue } from 'vue-property-decorator';
import { Indicador } from '@/plugins/api';
import CardIndicador from './components/CardIndicador.vue';
import ComprovantesGrid from './components/ComprovantesGrid.vue';

@Component({
  components: {
    CardIndicador,
    ComprovantesGrid,
  },
})
export default class InfoIndicador extends Vue {
  public loadingIndicador = false;
  public indicadorId!: number;
  public indicador = {} as IIndicador;
  public selectedTab = 0;

  get valor_pendente(): number {
    return this.indicador?.comissoes?.valor_pendente ?? 0;
  }

  get valor_disponivel(): number {
    return this.indicador?.comissoes?.valor_disponivel ?? 0;
  }

  get valor_total(): number {
    return this.indicador?.comissoes?.valor_total ?? 0;
  }

  get valor_solicitado(): number {
    return this.indicador?.comissoes?.valor_solicitado ?? 0;
  }

  get comprovantes(): IComprovante[] {
    return this.indicador?.comprovantes ?? [];
  }

  created(): void {
    this.indicadorId = parseInt(this.$route.params.id, 10);

    Indicador.getByID(this.indicadorId).then(
      (response: GetIndicadorResponse) => {
        this.loadingIndicador = false;
        this.indicador = response.data;
      }
    );
  }
}
